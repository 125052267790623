var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "api-based-select" },
    [
      _c(
        "Select",
        _vm._b(
          {
            model: {
              value: _vm.model,
              callback: function ($$v) {
                _vm.model = $$v
              },
              expression: "model",
            },
          },
          "Select",
          _vm.$attrs,
          false
        ),
        _vm._l(_vm.selectOptions, function (item) {
          return _c(
            "Option",
            {
              key: item.value,
              attrs: { value: item.value, label: item.label },
            },
            [_vm._v(_vm._s(item.label))]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }