import {getRequest, postRequest, putRequest, postBodyRequest, getNoAuthRequest, postNoAuthRequest} from '@/libs/axios';

export const addTComboCategory = params => {
    return postBodyRequest('/tComboCategory/addTComboCategory', params);
};
export const deleteTComboCategory = params => {
    return postRequest('/tComboCategory/deleteTComboCategory', params);
};
export const updateTComboCategory = params => {
    return postBodyRequest('/tComboCategory/updateTComboCategory', params);
};
export const queryTComboCategoryList = params => {
    return getRequest('/tComboCategory/queryTComboCategoryList', params);
};
export const getTComboCategory = params => {
    return getRequest('/tComboCategory/getTComboCategory', params);
};

//同步体检项目到
export const syncComboCategory = params => {
    return postBodyRequest('/tComboCategory/syncComboCategory', params);
};

export const getTComboCategoryParentList = params => {
    return getRequest('/tComboCategory/getTComboCategoryParentList', params);
};

export const queryTComboCategoryTree = params => {
    return getRequest('/tComboCategory/queryTComboCategoryTree', params);
};

