var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "message-content",
      staticStyle: { display: "inline-block" },
    },
    [
      _c("Dropdown", { attrs: { trigger: "click", placement: "bottom-end" } }, [
        _c("div", { staticStyle: { display: "inline-block" } }, [
          _c(
            "div",
            { staticClass: "header-right-icon header-action" },
            [
              _c(
                "Badge",
                { attrs: { count: _vm.messageCount } },
                [
                  _c("Icon", {
                    staticStyle: { "margin-top": "3px" },
                    attrs: { type: "md-notifications", size: 20 },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "message-tip", attrs: { slot: "list" }, slot: "list" },
          [
            _c("div", { staticClass: "title-content" }, [
              _c("div", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.$t("Notifications"))),
              ]),
              _vm.messageList.length > 0
                ? _c("a", { staticClass: "mark", on: { click: _vm.markAll } }, [
                    _vm._v(_vm._s(_vm.$t("markAll"))),
                  ])
                : _c("a", { staticClass: "mark", on: { click: _vm.getData } }, [
                    _vm._v(_vm._s(_vm.$t("refresh"))),
                  ]),
            ]),
            _c(
              "div",
              { staticClass: "mes-list" },
              [
                _vm.messageList.length == 0
                  ? _c(
                      "div",
                      { staticClass: "empty-message" },
                      [
                        _c("Icon", {
                          attrs: {
                            type: "ios-mail-open-outline",
                            size: "70",
                            color: "#e4e4e4",
                          },
                        }),
                        _c("p", [_vm._v(_vm._s(_vm.$t("noNewMes")))]),
                      ],
                      1
                    )
                  : _c("div"),
                _vm.loading ? _c("Spin", { attrs: { fix: "" } }) : _vm._e(),
              ],
              1
            ),
            _c(
              "a",
              { staticClass: "show-more", on: { click: _vm.showMessage } },
              [
                _c("Icon", {
                  staticStyle: { margin: "0 5px 2px 0" },
                  attrs: { type: "ios-arrow-dropright-circle", size: "16" },
                }),
                _vm._v(_vm._s(_vm.$t("showMore")) + " "),
              ],
              1
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }