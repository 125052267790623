var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loadingData || _vm.loading,
          expression: "loadingData || loading",
        },
      ],
      staticClass: "api-based-tree",
    },
    [
      _c("Tree", {
        key: _vm.modelValue,
        ref: "tree",
        attrs: { data: _vm.treeData, "children-key": _vm.childrenKey },
        on: { "on-select-change": _vm.handleSelectChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }