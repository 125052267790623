import { getRequest, postRequest, putRequest, postBodyRequest, getNoAuthRequest, postNoAuthRequest } from '@/libs/axios';
	export const addTScienceExplain = params => {
		return postBodyRequest('/tScienceExplain/addTScienceExplain',params);
	};
	export const deleteTScienceExplain = params => {
		return postRequest('/tScienceExplain/deleteTScienceExplain',params);
	};
	export const updateTScienceExplain = params => {
		return postBodyRequest('/tScienceExplain/updateTScienceExplain',params);
	};
	export const queryTScienceExplainList = params => {
		return getRequest('/tScienceExplain/queryTScienceExplainList',params);
	};
	export const getTScienceExplain = params => {
		return getRequest('/tScienceExplain/getTScienceExplain',params);
	};
