var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.leftWidth > 0
    ? _c(
        "div",
        [
          _vm._l(_vm.menuList, function (item, index) {
            return [
              _c(
                "div",
                {
                  key: index,
                  class: `shrink-menu-content shrink-menu-icon-${_vm.theme}`,
                },
                [
                  item.children.length != 1
                    ? _c(
                        "Dropdown",
                        {
                          key: index,
                          attrs: { placement: "right-start" },
                          on: { "on-click": _vm.changeMenu },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "shrink-icon",
                              class: { active: item.name == _vm.openNames[0] },
                            },
                            [
                              _c("XIcon", {
                                attrs: { size: 20, type: item.icon },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "DropdownMenu",
                            {
                              staticStyle: { width: "200px" },
                              attrs: { slot: "list" },
                              slot: "list",
                            },
                            _vm._l(item.children, function (child, i) {
                              return _c(
                                "DropdownItem",
                                {
                                  key: i,
                                  attrs: {
                                    name: child.name,
                                    selected: _vm.$route.name == child.name,
                                  },
                                },
                                [
                                  _c("XIcon", { attrs: { type: child.icon } }),
                                  _c(
                                    "span",
                                    { staticStyle: { "padding-left": "10px" } },
                                    [_vm._v(_vm._s(_vm.itemTitle(child)))]
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "Dropdown",
                        {
                          key: index,
                          attrs: { placement: "right-start" },
                          on: { "on-click": _vm.changeMenu },
                        },
                        [
                          _c(
                            "Tooltip",
                            {
                              attrs: {
                                content: item.title,
                                placement: "right",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "shrink-icon",
                                  class: {
                                    active: item.name == _vm.openNames[0],
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeMenu(
                                        item.children[0].name
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("XIcon", {
                                    attrs: {
                                      size: 20,
                                      type: item.children[0].icon || item.icon,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                ],
                1
              ),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }