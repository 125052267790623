var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: `shrinkable-menu ${_vm.theme}-menu`,
      style: { background: _vm.bgColor },
    },
    [
      _vm._t("top"),
      _c("sidebar-menu", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.shrink,
            expression: "!shrink",
          },
        ],
        attrs: {
          theme: _vm.theme,
          "menu-list": _vm.menuList,
          "open-names": _vm.openNames,
        },
        on: { "on-change": _vm.handleChange },
      }),
      _c("sidebar-menu-shrink", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.shrink,
            expression: "shrink",
          },
        ],
        attrs: {
          theme: _vm.theme,
          "menu-list": _vm.menuList,
          "open-names": _vm.openNames,
        },
        on: { "on-change": _vm.handleChange },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }