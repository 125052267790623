var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "api-based-check" },
    [
      _c(
        "checkbox-group",
        _vm._b(
          {
            model: {
              value: _vm.model,
              callback: function ($$v) {
                _vm.model = $$v
              },
              expression: "model",
            },
          },
          "checkbox-group",
          _vm.$attrs,
          false
        ),
        _vm._l(_vm.checkoptions, function (item) {
          return _c(
            "Checkbox",
            { key: item.value, attrs: { label: item.value } },
            [_vm._v(_vm._s(item.label))]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }