import { render, staticRenderFns } from "./breadcrumb-nav.vue?vue&type=template&id=4b629a10&"
import script from "./breadcrumb-nav.vue?vue&type=script&lang=js&"
export * from "./breadcrumb-nav.vue?vue&type=script&lang=js&"
import style0 from "./breadcrumb-nav.vue?vue&type=style&index=0&id=4b629a10&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Java\\healthy\\healthy-frame-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4b629a10')) {
      api.createRecord('4b629a10', component.options)
    } else {
      api.reload('4b629a10', component.options)
    }
    module.hot.accept("./breadcrumb-nav.vue?vue&type=template&id=4b629a10&", function () {
      api.rerender('4b629a10', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/main-components/breadcrumb-nav.vue"
export default component.exports