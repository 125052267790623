import { getRequest, postRequest, putRequest, postBodyRequest, getNoAuthRequest, postNoAuthRequest } from '@/libs/axios';
	export const addTCombo = params => {
		return postBodyRequest('/tCombo/addTCombo',params);
	};
	export const deleteTCombo = params => {
		return postRequest('/tCombo/deleteTCombo',params);
	};
	export const updateTCombo = params => {
		return postBodyRequest('/tCombo/updateTCombo',params);
	};
	export const queryTComboList = params => {
		return getRequest('/tCombo/queryTComboList',params);
	};
	export const getTCombo = params => {
		return getRequest('/tCombo/getTCombo',params);
	};
	export const getTComboByPersonId = params => {
		return getRequest('/tCombo/getTComboByPersonId',params);
	};
	export const gethazardFactorsByGroupId = params => {
		return getRequest('/tCombo/gethazardFactorsByGroupId',params);
	};
	export const getTcomboByName = params => {
		return getRequest('/tCombo/getTcomboByName',params);
	};
    export const copyTCombo = params => {
        return postBodyRequest('/tCombo/copyTCombo',params);
    };

	//同步体检项目到
export const syncTCombo = params => {
	return postBodyRequest('/tCombo/syncTCombo',params);
};
