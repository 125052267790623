var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { display: "inline-block" } },
    [
      _c(
        "div",
        {
          staticClass: "header-right-icon header-action",
          on: {
            click: function ($event) {
              _vm.visible = true
            },
          },
        },
        [_c("Icon", { attrs: { type: "md-more", size: "20" } })],
        1
      ),
      _c(
        "Drawer",
        {
          attrs: { title: _vm.$t("themeTitle"), width: "300" },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c(
            "div",
            { staticClass: "theme-setting" },
            [
              _c("div", { staticClass: "theme-item-content" }, [
                _c("h3", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$t("wholeSetting"))),
                ]),
                _c(
                  "div",
                  { staticClass: "checkbox-content" },
                  [
                    _c(
                      "Tooltip",
                      {
                        attrs: {
                          transfer: "",
                          placement: "top",
                          content: _vm.$t("lightblue"),
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "checkbox-item checkbox-item-primary",
                            on: {
                              click: function ($event) {
                                return _vm.changeMainTheme("primary")
                              },
                            },
                          },
                          [
                            _vm.mainTheme == "primary"
                              ? _c("Icon", {
                                  staticClass: "checkbox-select-icon",
                                  attrs: { type: "md-checkmark" },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "Tooltip",
                      { attrs: { placement: "top", content: _vm.$t("white") } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "checkbox-item checkbox-item-light",
                            on: {
                              click: function ($event) {
                                return _vm.changeMainTheme("light")
                              },
                            },
                          },
                          [
                            _vm.mainTheme == "light"
                              ? _c("Icon", {
                                  staticClass: "checkbox-select-icon",
                                  attrs: { type: "md-checkmark" },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "Tooltip",
                      {
                        attrs: { placement: "top", content: _vm.$t("topNav") },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "checkbox-item checkbox-item-nav-black checkbox-item-nav",
                            on: {
                              click: function ($event) {
                                return _vm.changeMainTheme("topNav")
                              },
                            },
                          },
                          [
                            _vm.mainTheme == "topNav"
                              ? _c("Icon", {
                                  staticClass: "checkbox-select-icon",
                                  attrs: { type: "md-checkmark" },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "Tooltip",
                      {
                        attrs: {
                          placement: "top",
                          content: _vm.$t("darkMode"),
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "checkbox-item checkbox-item-darkMode",
                            on: {
                              click: function ($event) {
                                return _vm.changeMainTheme("darkMode")
                              },
                            },
                          },
                          [
                            _vm.mainTheme == "darkMode"
                              ? _c("Icon", {
                                  staticClass: "checkbox-select-icon",
                                  attrs: {
                                    type: "md-checkmark",
                                    color: "#177ddc",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c("Divider"),
              _c("div", { staticClass: "theme-item-content" }, [
                _c("h3", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$t("sideMenu"))),
                ]),
                _c(
                  "div",
                  { staticClass: "checkbox-content" },
                  [
                    _c(
                      "Tooltip",
                      {
                        attrs: {
                          transfer: "",
                          placement: "top",
                          content: _vm.$t("darkblue"),
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "checkbox-item checkbox-item-darkblue",
                            on: {
                              click: function ($event) {
                                return _vm.changeMenuTheme("darkblue")
                              },
                            },
                          },
                          [
                            _vm.menuTheme == "darkblue"
                              ? _c("Icon", {
                                  staticClass: "checkbox-select-icon",
                                  attrs: { type: "md-checkmark" },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "Tooltip",
                      { attrs: { placement: "top", content: _vm.$t("dark") } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "checkbox-item checkbox-item-black",
                            on: {
                              click: function ($event) {
                                return _vm.changeMenuTheme("black")
                              },
                            },
                          },
                          [
                            _vm.menuTheme == "black"
                              ? _c("Icon", {
                                  staticClass: "checkbox-select-icon",
                                  attrs: { type: "md-checkmark" },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "Tooltip",
                      {
                        attrs: { placement: "top", content: _vm.$t("middle") },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "checkbox-item checkbox-item-dark",
                            on: {
                              click: function ($event) {
                                return _vm.changeMenuTheme("dark")
                              },
                            },
                          },
                          [
                            _vm.menuTheme == "dark"
                              ? _c("Icon", {
                                  staticClass: "checkbox-select-icon",
                                  attrs: { type: "md-checkmark" },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "Tooltip",
                      { attrs: { placement: "top", content: _vm.$t("white") } },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "checkbox-item checkbox-item-side-light",
                            on: {
                              click: function ($event) {
                                return _vm.changeMenuTheme("light")
                              },
                            },
                          },
                          [
                            _vm.menuTheme == "light"
                              ? _c("Icon", {
                                  staticClass: "checkbox-select-icon",
                                  attrs: { type: "md-checkmark" },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "theme-item-content" }, [
                _c("h3", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$t("navMenu"))),
                ]),
                _c(
                  "div",
                  { staticClass: "checkbox-content" },
                  [
                    _c(
                      "Tooltip",
                      {
                        attrs: {
                          transfer: "",
                          placement: "top",
                          content: _vm.$t("darkblue"),
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "checkbox-item checkbox-item-nav-darkblue checkbox-item-nav",
                            on: {
                              click: function ($event) {
                                return _vm.changeNavTheme("darkblue")
                              },
                            },
                          },
                          [
                            _vm.navTheme == "darkblue"
                              ? _c("Icon", {
                                  staticClass: "checkbox-select-icon",
                                  attrs: { type: "md-checkmark" },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "Tooltip",
                      { attrs: { placement: "top", content: _vm.$t("dark") } },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "checkbox-item checkbox-item-nav-black checkbox-item-nav",
                            on: {
                              click: function ($event) {
                                return _vm.changeNavTheme("black")
                              },
                            },
                          },
                          [
                            _vm.navTheme == "black"
                              ? _c("Icon", {
                                  staticClass: "checkbox-select-icon",
                                  attrs: { type: "md-checkmark" },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "Tooltip",
                      {
                        attrs: {
                          placement: "top",
                          content: _vm.$t("lightblue"),
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "checkbox-item checkbox-item-nav-primary checkbox-item-nav",
                            on: {
                              click: function ($event) {
                                return _vm.changeNavTheme("primary")
                              },
                            },
                          },
                          [
                            _vm.navTheme == "primary"
                              ? _c("Icon", {
                                  staticClass: "checkbox-select-icon",
                                  attrs: { type: "md-checkmark" },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "Tooltip",
                      { attrs: { placement: "top", content: _vm.$t("white") } },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "checkbox-item checkbox-item-nav-light checkbox-item-nav",
                            on: {
                              click: function ($event) {
                                return _vm.changeNavTheme("light")
                              },
                            },
                          },
                          [
                            _vm.navTheme == "light"
                              ? _c("Icon", {
                                  staticClass: "checkbox-select-icon",
                                  attrs: { type: "md-checkmark" },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c("Divider"),
              _c("div", { staticStyle: { "margin-bottom": "24px" } }, [
                _c("h3", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$t("navMode"))),
                ]),
                _c(
                  "div",
                  { staticClass: "checkbox-content" },
                  [
                    _c(
                      "Tooltip",
                      {
                        attrs: {
                          transfer: "",
                          placement: "top",
                          content: _vm.$t("rightTop"),
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "checkbox-item checkbox-item-topright checkbox-layout-item",
                            on: {
                              click: function ($event) {
                                return _vm.changeFixNav(false)
                              },
                            },
                          },
                          [
                            !_vm.fixNav
                              ? _c("Icon", {
                                  staticClass: "checkbox-select-icon",
                                  attrs: { type: "md-checkmark" },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "Tooltip",
                      {
                        attrs: { placement: "top", content: _vm.$t("topFix") },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "checkbox-item checkbox-item-top checkbox-layout-item",
                            on: {
                              click: function ($event) {
                                return _vm.changeFixNav(true)
                              },
                            },
                          },
                          [
                            _vm.fixNav
                              ? _c("Icon", {
                                  staticClass: "checkbox-select-icon",
                                  attrs: { type: "md-checkmark" },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "theme-list" }, [
                  _c(
                    "div",
                    { staticClass: "theme-list-item" },
                    [
                      _c("div", { staticClass: "item-title" }, [
                        _vm._v(_vm._s(_vm.$t("navType"))),
                      ]),
                      _c(
                        "Select",
                        {
                          staticStyle: { width: "150px" },
                          attrs: { value: _vm.navType, size: "small" },
                          on: { "on-change": _vm.changeNavType },
                        },
                        [
                          _c("Option", { attrs: { value: "1" } }, [
                            _vm._v(_vm._s(_vm.$t("horizontal"))),
                          ]),
                          _c("Option", { attrs: { value: "2" } }, [
                            _vm._v(_vm._s(_vm.$t("singleDrop"))),
                          ]),
                          _c("Option", { attrs: { value: "3" } }, [
                            _vm._v(_vm._s(_vm.$t("iconBreadcrumb"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.navType == 1
                    ? _c(
                        "div",
                        { staticClass: "theme-list-item" },
                        [
                          _c("div", { staticClass: "item-title" }, [
                            _vm._v(_vm._s(_vm.$t("sliceNum"))),
                          ]),
                          _c("InputNumber", {
                            staticStyle: { width: "100px" },
                            attrs: {
                              min: 1,
                              max: 10,
                              value: _vm.sliceNum,
                              size: "small",
                            },
                            on: { "on-change": _vm.changeSliceNum },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.navType == 1
                    ? _c(
                        "div",
                        { staticClass: "theme-list-item" },
                        [
                          _c("div", { staticClass: "item-title" }, [
                            _vm._v(_vm._s(_vm.$t("showIcon"))),
                          ]),
                          _c("i-switch", {
                            attrs: {
                              value: _vm.showNavMenuIcon,
                              size: "small",
                            },
                            on: { "on-change": _vm.changeShowNavMenuIcon },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.navType == 2 || _vm.navType == 3
                    ? _c(
                        "div",
                        { staticClass: "theme-list-item" },
                        [
                          _c("div", { staticClass: "item-title" }, [
                            _vm._v(_vm._s(_vm.$t("dropMenu"))),
                          ]),
                          _c(
                            "Select",
                            {
                              staticStyle: { width: "150px" },
                              attrs: { value: _vm.navShowType, size: "small" },
                              on: { "on-change": _vm.changeNavShowType },
                            },
                            [
                              _c("Option", { attrs: { value: "app" } }, [
                                _vm._v(_vm._s(_vm.$t("appStyle"))),
                              ]),
                              _c("Option", { attrs: { value: "list" } }, [
                                _vm._v(_vm._s(_vm.$t("listStyle"))),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
              _c("Divider"),
              _c("div", { staticStyle: { "margin-bottom": "24px" } }, [
                _c("h3", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$t("contentArea"))),
                ]),
                _c("div", { staticClass: "theme-list" }, [
                  _c(
                    "div",
                    { staticClass: "theme-list-item" },
                    [
                      _c("div", { staticClass: "item-title" }, [
                        _vm._v(_vm._s(_vm.$t("enableTags"))),
                      ]),
                      _c("i-switch", {
                        attrs: { value: _vm.showTags, size: "small" },
                        on: { "on-change": _vm.changeShowTags },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "theme-list-item" },
                    [
                      _c("div", { staticClass: "item-title" }, [
                        _vm._v(_vm._s(_vm.$t("showFold"))),
                      ]),
                      _c("i-switch", {
                        attrs: { value: _vm.showFold, size: "small" },
                        on: { "on-change": _vm.changeShowFold },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "theme-list-item" },
                    [
                      _c("div", { staticClass: "item-title" }, [
                        _vm._v(_vm._s(_vm.$t("showLogo"))),
                      ]),
                      _c("i-switch", {
                        attrs: { value: _vm.showLogo, size: "small" },
                        on: { "on-change": _vm.changeShowLogo },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "theme-list-item" },
                    [
                      _c("div", { staticClass: "item-title" }, [
                        _vm._v(_vm._s(_vm.$t("showSearchMenu"))),
                      ]),
                      _c("i-switch", {
                        attrs: { value: _vm.showSearch, size: "small" },
                        on: { "on-change": _vm.changeShowSearch },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "theme-list-item" },
                    [
                      _c("div", { staticClass: "item-title" }, [
                        _vm._v(_vm._s(_vm.$t("showFooter"))),
                      ]),
                      _c("i-switch", {
                        attrs: { value: _vm.showFooter, size: "small" },
                        on: { "on-change": _vm.changeShowFooter },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "theme-list-item" },
                    [
                      _c("div", { staticClass: "item-title" }, [
                        _vm._v(_vm._s(_vm.$t("sideMenuWidth")) + "（px）"),
                      ]),
                      _c("InputNumber", {
                        staticStyle: { width: "100px" },
                        attrs: {
                          min: 0,
                          max: 500,
                          value: _vm.menuWidthNum,
                          size: "small",
                        },
                        on: { "on-change": _vm.changeMenuWidth },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("Divider"),
              _c("div", { staticStyle: { "margin-bottom": "24px" } }, [
                _c("h3", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$t("otherSetting"))),
                ]),
                _c("div", { staticClass: "theme-list" }, [
                  _c(
                    "div",
                    { staticClass: "theme-list-item" },
                    [
                      _c("div", { staticClass: "item-title" }, [
                        _vm._v(_vm._s(_vm.$t("weakMode"))),
                      ]),
                      _c("i-switch", {
                        attrs: { value: _vm.weakMode, size: "small" },
                        on: { "on-change": _vm.changeWeakMode },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("Alert", { attrs: { type: "warning", "show-icon": "" } }, [
                _vm._v(_vm._s(_vm.$t("themeInfo"))),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }