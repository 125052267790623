var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main", class: { "main-hide-text": _vm.shrink } },
    [
      _c(
        "div",
        {
          class: `sidebar-menu-content side-bar-menu-theme-${_vm.menuTheme}`,
          style: {
            width: _vm.shrink ? _vm.leftWidth + "px" : _vm.menuWidth + "px",
            overflow: _vm.shrink ? "visible" : "auto",
          },
        },
        [
          _c(
            "shrinkable-menu",
            {
              attrs: {
                shrink: _vm.shrink,
                theme: _vm.menuTheme,
                "before-push": _vm.beforePush,
                "menu-list": _vm.menuList,
              },
              on: { "on-change": _vm.handleSubmenuChange },
            },
            [
              _vm.showLogo || _vm.fixNav
                ? _c(
                    "div",
                    {
                      staticClass: "logo-content",
                      attrs: { slot: "top" },
                      slot: "top",
                    },
                    [
                      _c("img", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              !_vm.shrink &&
                              (_vm.menuTheme != "light" ||
                                _vm.mainTheme == "darkMode"),
                            expression:
                              "!shrink && (menuTheme != 'light' || mainTheme == 'darkMode')",
                          },
                        ],
                        attrs: { src: require("@/assets/logo-back.png") },
                      }),
                      _c("img", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              !_vm.shrink &&
                              _vm.menuTheme == "light" &&
                              _vm.mainTheme != "darkMode",
                            expression:
                              "!shrink && menuTheme == 'light' && mainTheme != 'darkMode'",
                          },
                        ],
                        attrs: { src: require("@/assets/logo-back.png") },
                      }),
                      _c("img", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.shrink,
                            expression: "shrink",
                          },
                        ],
                        key: "min-logo",
                        attrs: { src: require("@/assets/logo-min.png") },
                      }),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          class: `main-header-content fix-nav-${_vm.fixNav}`,
          style: { paddingLeft: _vm.navPaddingLeft },
        },
        [
          _c("div", { class: `main-header header-theme-${_vm.navTheme}` }, [
            _c("div", { staticClass: "header-left" }, [
              _vm.fixNav && _vm.showLogo
                ? _c(
                    "div",
                    {
                      staticClass: "fix-logo",
                      staticStyle: { width: "220px" },
                      on: { click: _vm.selectProcessShow },
                    },
                    [
                      _vm.navTheme == "light" && _vm.mainTheme != "darkMode"
                        ? _c("img", {
                            attrs: { src: require("@/assets/logo-back.png") },
                          })
                        : _c("img", {
                            key: "max-logo",
                            attrs: { src: require("@/assets/logo-back.png") },
                          }),
                    ]
                  )
                : _vm._e(),
              _vm.showFold
                ? _c(
                    "div",
                    {
                      staticClass: "header-navicon-content header-action",
                      on: { click: _vm.toggleClick },
                    },
                    [
                      _c("Icon", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.shrink,
                            expression: "shrink",
                          },
                        ],
                        attrs: {
                          custom: "iconfont icon-menu-unfold",
                          size: "20",
                        },
                      }),
                      _c("Icon", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.shrink,
                            expression: "!shrink",
                          },
                        ],
                        attrs: {
                          custom: "iconfont icon-menu-fold",
                          size: "20",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "header-middle-content" }, [
                _vm.navType == 1
                  ? _c(
                      "div",
                      [
                        _c("navMenu", {
                          attrs: {
                            theme: _vm.navTheme,
                            currNav: _vm.currNav,
                            navList: _vm.navList,
                            sliceNum: _vm.sliceNum,
                            showIcon: _vm.showNavMenuIcon,
                          },
                          on: { "on-click": _vm.selectNav },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.navType == 2
                  ? _c(
                      "div",
                      [
                        _c("navApp", {
                          attrs: {
                            currType: "tabs",
                            placement: "bottom-start",
                            currNavTitle: _vm.currNavTitle,
                            currNav: _vm.currNav,
                            navList: _vm.navList,
                            type: _vm.navShowType,
                            theme: _vm.navTheme,
                          },
                          on: { "on-click": _vm.selectNav },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.navType == 3
                  ? _c(
                      "div",
                      { staticClass: "main-breadcrumb" },
                      [
                        _c("breadcrumb-nav", {
                          attrs: {
                            theme: _vm.navTheme,
                            currentPath: _vm.currentPath,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "header-right-content" },
              [
                _vm.showSearch
                  ? _c("search", {
                      attrs: { theme: _vm.navTheme, type: _vm.searchType },
                    })
                  : _vm._e(),
                _vm.navType == 3
                  ? _c("navApp", {
                      attrs: {
                        currNav: _vm.currNav,
                        navList: _vm.navList,
                        type: _vm.navShowType,
                      },
                      on: { "on-click": _vm.selectNav },
                    })
                  : _vm._e(),
                _c("full-screen", {
                  on: { "on-change": _vm.fullscreenChange },
                  model: {
                    value: _vm.isFullScreen,
                    callback: function ($$v) {
                      _vm.isFullScreen = $$v
                    },
                    expression: "isFullScreen",
                  },
                }),
                _c("language"),
                _c("message-tip"),
                _c("user"),
                _vm.nickname == "管理员" ? _c("theme") : _vm._e(),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "nav-tags",
          style: {
            paddingLeft: _vm.shrink
              ? _vm.leftWidth + "px"
              : _vm.menuWidth + "px",
          },
        },
        [
          _vm.showTags
            ? _c("tags-page-opened", {
                attrs: { pageTagsList: _vm.pageTagsList },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "single-page-content",
          style: {
            left: _vm.shrink ? _vm.leftWidth + "px" : _vm.menuWidth + "px",
            top: _vm.showTags ? "100px" : "60px",
            padding: "0px",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "single-page",
              style: {
                height: _vm.showTags
                  ? "calc(100vh - 100px)"
                  : "calc(100vh - 60px)",
              },
            },
            [
              _c(
                "keep-alive",
                { attrs: { include: _vm.cachePage } },
                [_c("router-view")],
                1
              ),
            ],
            1
          ),
          _vm.showFooter
            ? _c(
                "div",
                { staticClass: "main-page-footer-content" },
                [_c("Footer", { staticClass: "main-page-footer" })],
                1
              )
            : _vm._e(),
        ]
      ),
      _c("circleLoading", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "loading-position",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }