var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "data-row-actions" },
    [
      _vm._l(_vm.buttons, function ({ event, label, ...props }) {
        return _c(
          "Button",
          _vm._b(
            {
              key: event,
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  return _vm.handleClick(event)
                },
              },
            },
            "Button",
            props,
            false
          ),
          [_vm._v(_vm._s(label))]
        )
      }),
      _vm.showDropdown
        ? _c(
            "Dropdown",
            {
              on: { "on-click": _vm.handleDropdown },
              scopedSlots: _vm._u(
                [
                  {
                    key: "list",
                    fn: function () {
                      return [
                        _c(
                          "dropdown-menu",
                          _vm._l(
                            _vm.items,
                            function ({ event, label, ...props }) {
                              return _c(
                                "dropdown-item",
                                _vm._b(
                                  { key: event, attrs: { name: event } },
                                  "dropdown-item",
                                  props,
                                  false
                                ),
                                [_vm._v(_vm._s(label))]
                              )
                            }
                          ),
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1751916281
              ),
            },
            [
              _c(
                "Button",
                { attrs: { size: "small" } },
                [
                  _c("span", [_vm._v("更多操作")]),
                  _c("icon", { attrs: { type: "md-arrow-dropdown" } }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }