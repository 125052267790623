var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showFullScreenBtn
    ? _c(
        "div",
        {
          staticStyle: { display: "inline-block" },
          on: { click: _vm.handleChange },
        },
        [
          _c(
            "Tooltip",
            {
              attrs: {
                content: _vm.value
                  ? _vm.$t("exitFullscreen")
                  : _vm.$t("fullscreen"),
                placement: "bottom",
              },
            },
            [
              _c(
                "div",
                { staticClass: "header-right-icon header-action" },
                [
                  _c("Icon", {
                    attrs: {
                      type: _vm.value ? "md-contract" : "md-expand",
                      size: 20,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }