var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Row",
    {
      staticClass: "data-page",
      style: `width: calc(100% - ${_vm.$slots["aside"] ? 16 : 0}px)`,
    },
    [
      _c(
        "Col",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showAside,
              expression: "showAside",
            },
          ],
          staticClass: "data-page__aside",
          attrs: { span: 5 },
        },
        [_c("Card", [_vm._t("aside")], 2)],
        1
      ),
      _vm.$slots["aside"]
        ? _c(
            "div",
            { staticClass: "data-page__expend" },
            [
              _c("icon", {
                staticClass: "toggle-icon",
                attrs: {
                  type: _vm.expend ? "ios-arrow-back" : "ios-arrow-forward",
                },
                on: {
                  click: function ($event) {
                    _vm.expend = !_vm.expend
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "Col",
        {
          staticClass: "data-page__content",
          attrs: { span: _vm.showAside ? 19 : 24 },
        },
        [
          _c("Card", [
            _vm.$slots["query"]
              ? _c(
                  "div",
                  { staticClass: "content__query" },
                  [_vm._t("query")],
                  2
                )
              : _vm._e(),
            _vm.$slots["header"]
              ? _c(
                  "div",
                  { staticClass: "content__header" },
                  [_vm._t("header")],
                  2
                )
              : _vm._e(),
            _c("div", { staticClass: "content__body" }, [_vm._t("default")], 2),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }