var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { display: "inline-block" } },
    [
      _c(
        "Dropdown",
        {
          attrs: { placement: _vm.placement },
          on: { "on-click": _vm.selectNav },
        },
        [
          _vm.currType == "icon"
            ? _c(
                "div",
                { staticClass: "header-right-icon header-action" },
                [_c("XIcon", { attrs: { type: "ios-apps", size: 20 } })],
                1
              )
            : _c(
                "div",
                {
                  class: `single-nav header-action single-nav-theme-${_vm.theme} `,
                },
                [
                  _vm._v(" " + _vm._s(_vm.currNavTitle) + " "),
                  _c("XIcon", {
                    staticClass: "single-nav-dropdown-icon",
                    attrs: { type: "ios-arrow-down" },
                  }),
                ],
                1
              ),
          _vm.type == "list"
            ? _c(
                "DropdownMenu",
                { attrs: { slot: "list" }, slot: "list" },
                _vm._l(_vm.navList, function (item, i) {
                  return _c(
                    "DropdownItem",
                    {
                      key: i,
                      attrs: {
                        name: item.name,
                        selected: _vm.currNav == item.name,
                      },
                    },
                    [
                      _c(
                        "Badge",
                        { attrs: { dot: item.component == "hot" } },
                        [
                          _c("XIcon", {
                            staticStyle: { margin: "0 10px 0 0" },
                            attrs: { type: item.icon, size: 14 },
                          }),
                          _vm._v(" " + _vm._s(item.title) + " "),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              )
            : _c(
                "div",
                { attrs: { slot: "list" }, slot: "list" },
                [
                  _c("apps", {
                    attrs: { currNav: _vm.currNav, navList: _vm.navList },
                    on: { "on-click": _vm.selectNav },
                  }),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }