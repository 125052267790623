var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "foot" },
    [
      _c(
        "Row",
        {
          staticClass: "foot-item",
          attrs: { type: "flex", justify: "space-around" },
        },
        [
          _c(
            "a",
            { staticClass: "item", attrs: { href: "", target: "_blank" } },
            [_vm._v(_vm._s(_vm.$t("docs")))]
          ),
          _c(
            "a",
            { staticClass: "item", attrs: { href: "", target: "_blank" } },
            [_vm._v(_vm._s(_vm.$t("fullVersion")))]
          ),
        ]
      ),
      _c(
        "Row",
        {
          staticClass: "copyright",
          attrs: { type: "flex", justify: "center" },
        },
        [
          _vm._v(" Copyright © 2019 - Present "),
          _c(
            "a",
            {
              staticStyle: { margin: "0 5px" },
              attrs: { href: "", target: "_blank" },
            },
            [_vm._v("数智医疗信息技术有限公司")]
          ),
          _vm._v(" " + _vm._s(_vm.$t("rights")) + " "),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }