export const obj = [{
    name: "健康体检系统",
    isShow: true,
    id: 2
}, {
    name: "从业体检系统",
    isShow: true,
    id: 3
}, {
    name: "职业体检系统",
    isShow: true,
    id: 1
}, {
    name: "放射体检系统",
    isShow: true,
    id: 4
}, {
    name: "LIS系统",
    isShow: true,
    id: 5
}, {
    name: "PACS系统",
    isShow: true,
    id: 6
},{
    name: "财务管理",
    isShow: true,
    id: 7
}];
