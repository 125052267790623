var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-header" }, [
    _c(
      "div",
      { staticClass: "page-header__begin" },
      [
        _vm._l(_vm.buttons, function ({ event, label, ...props }) {
          return _c(
            "Button",
            _vm._b(
              {
                key: event,
                on: {
                  click: function ($event) {
                    return _vm.handleClick(event)
                  },
                },
              },
              "Button",
              props,
              false
            ),
            [_vm._v(_vm._s(label))]
          )
        }),
        _vm.showDropdown
          ? _c(
              "Dropdown",
              {
                attrs: { trigger: "click" },
                on: { "on-click": _vm.handleDropdown },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "list",
                      fn: function () {
                        return [
                          _c(
                            "dropdown-menu",
                            _vm._l(_vm.items, function (item) {
                              return _c(
                                "dropdown-item",
                                {
                                  key: item.event,
                                  attrs: { name: item.event },
                                },
                                [_vm._v(_vm._s(item.label))]
                              )
                            }),
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  1117776443
                ),
              },
              [_c("Button", [_vm._v("更多操作")])],
              1
            )
          : _vm._e(),
      ],
      2
    ),
    _c("div", { staticClass: "page-header__end" }, [_vm._t("end")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }