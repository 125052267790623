var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "data-pagination" },
    [
      _c(
        "Page",
        _vm._b(
          {
            on: {
              "on-change": _vm.handlePageChange,
              "on-prev": _vm.handlePageChange,
              "on-next": _vm.handlePageChange,
              "on-page-size-change": _vm.handlePageSizeChange,
            },
          },
          "Page",
          _vm.paginationOptions,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }