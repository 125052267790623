<template lang="pug">
.api-based-check
  checkbox-group(
    v-bind="$attrs"
    v-model="model"
  )
    Checkbox(
      v-for="item of checkoptions"
      :key="item.value"
      :label="item.value"
    ) {{  item.label }}

</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: undefined,
    },
    loadMethod: {
      type: Function,
      default: undefined,
    },
    separator: {
      type: String,
      default: ";",
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      model: [],
      checkoptions: [],
    };
  },
  watch: {
    modelValue: {
      handler(val) {
        const newModel = val.length
          ? Array.from(new Set(val.split(this.separator)))
          : [];
        if (this.isEqual(this.model, newModel)) return;

        this.model = newModel;
      },
      immediate: true,
    },

    model(val) {
      this.$emit("update:modelValue", val.join(this.separator));
    },
  },
  created() {
    this.init();
  },
  methods: {
    isEqual(target, source) {
      if (target.length !== source.length) {
        return false;
      } else {
        return target.every((t) => source.some((s) => t === s));
      }
      return false;
    },
    async init() {
      if (this.options) {
        this.checkoptions = this.options;
        return;
      }

      if (typeof this.loadMethod === "function") {
        this.checkoptions = await this.loadMethod();

        return;
      }
    },
  },
};
</script>

<style lang="less" scoped></style>
