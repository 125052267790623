<template lang="pug">
.api-based-select
  Select(
    v-bind="$attrs"
    v-model="model"
  )
    Option(
      v-for="item of selectOptions"
      :key="item.value"
      :value="item.value"
      :label="item.label"
    ) {{  item.label }}

</template>

<script>
export default {
  inheritAttrs: false,
  model: {
    prop: "modelValue",
    event: "update:modelValue",
  },
  props: {
    modelValue: {
      type: Array | String,
      default: "",
    },
    modelLabel: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: undefined,
    },
    loadMethod: {
      type: Function,
      default: undefined,
    },
    separator: {
      type: String,
      default: ";",
    },
    labelAsValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      model: "",
      selectOptions: [],
    };
  },
  watch: {
    modelValue: {
      handler(val) {
        if (this.model === val) return;

        this.model = this.formatValue(val);
      },
      immediate: true,
    },

    model(val) {
      const options = []
        .concat(val)
        .map((v) => this.selectOptions.find((i) => v === i.value))
        .filter(Boolean);

      const label = options.map((i) => i.label);

      this.$emit(
        "update:modelValue",
        this.formatValue(this.labelAsValue ? label : val, false),
        this.$attrs.multiple ? options : options[0]
      );

      this.$emit(
        "update:modelLabel",
        this.formatValue(label, false),
        this.$attrs.multiple ? options : options[0]
      );
    },
  },
  created() {
    this.init();
  },
  methods: {
    formatValue(val, toArr = true) {
      // 如果不是多选 直接返回值
      if (!this.$attrs.multiple)
        return Array.isArray(val) ? val.toString() : val;

      return toArr ? val.split(this.separator) : val.join(this.separator);
    },
    async init() {
      if (this.options) {
        this.selectOptions = this.options;
      } else if (typeof this.loadMethod === "function") {
        this.selectOptions = await this.loadMethod();
      }
    },
  },
};
</script>

<style lang="less" scoped></style>
