var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "scrollCon",
      staticClass: "tags-scroll-content",
      on: { DOMMouseScroll: _vm.handlescroll, mousewheel: _vm.handlescroll },
    },
    [
      _c(
        "div",
        {
          ref: "scrollBody",
          staticClass: "tags-scroll-body",
          style: { left: _vm.tagBodyLeft + "px" },
        },
        [
          _c(
            "transition-group",
            { attrs: { name: "taglist-moving-animation" } },
            _vm._l(_vm.pageTagsList, function (item) {
              return _c(
                "Tag",
                {
                  key: item.name,
                  ref: "tagsPageOpened",
                  refInFor: true,
                  attrs: {
                    type: "dot",
                    name: item.name,
                    closable: item.name == "home_index" ? false : true,
                    color: item.children
                      ? item.children[0].name == _vm.currentPageName
                        ? "primary"
                        : "default"
                      : item.name == _vm.currentPageName
                      ? "primary"
                      : "default",
                  },
                  on: { "on-close": _vm.closePage },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.linkTo(item)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.itemTitle(item)) + " ")]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "close-tag-content" },
        [
          _c(
            "Dropdown",
            {
              attrs: {
                transfer: "",
                trigger: "hover",
                placement: "bottom-end",
                "transfer-class-name": "close-tag-dropdown",
              },
              on: { "on-click": _vm.handleTagsOption },
            },
            [
              _c(
                "div",
                { staticClass: "icon-content" },
                [_c("Icon", { attrs: { type: "ios-arrow-down", size: "16" } })],
                1
              ),
              _c(
                "DropdownMenu",
                { attrs: { slot: "list" }, slot: "list" },
                [
                  _c("DropdownItem", { attrs: { name: "clearAll" } }, [
                    _vm._v(_vm._s(_vm.$t("closeAll"))),
                  ]),
                  _c("DropdownItem", { attrs: { name: "clearOthers" } }, [
                    _vm._v(_vm._s(_vm.$t("closeOthers")) + " "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }